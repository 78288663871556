import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@environments/environment';

@Pipe({
    name: 'profile',
    standalone: true,
    pure:false
})
export class ProfilePipe implements PipeTransform {
    titlecase: TitleCasePipe = new TitleCasePipe();
    transform(value: unknown, ...args: unknown[]): string {
        const data: any = value;

        if (!data) return '';

        switch (args[0]) {
            case 'id':
                return this.getId(data);
            case 'firstName':
                return this.getTransformedInfo(data, 'firstName');
            case 'lastName':
                return this.getTransformedInfo(data, 'lastName');
            case 'img-empty':
                return this.getEmptyImage(data);
            case 'userName':
                return this.getFullName(data);
            case 'numberStart':
                return this.getNumberStart(data);
            // case 'numberStartPain':
            //     return this.getNumberStartPain(data);
            case 'donations':
                return this.getStats(data, 'donationNumber');
            case 'exchanges':
                return this.getStats(data, 'numberExchanges');
            case 'shares':
                return this.getStats(data, 'numberShares');
            case 'likes':
                return this.getStats(data, 'likes');
            case 'followers':
                return this.getStats(data, 'followers');
            case 'description':
                return this.getDescription(data);
            case 'kind':
                return this.getAcknowledgmentKind(data);
            case 'title':
                return this.getTitle(data);
            case 'img':
                return this.getProfileImage(data);
            case 'imgFollower':
                return this.getFollowerImage(data);
            case 'fullname':
                return this.getFullName(data);
            case 'image':
                return this.getUserImage(data);
            default:
                return '';
        }
    }

    private getId(data: any): string {
        return data._id || '';
    }

    private getTransformedInfo(data: any, field: string): string {
        return this.titlecase.transform(data.info[field]) || '';
    }

    private getEmptyImage(data: any): string {
        if (!data) return 'assets/img/no-image.svg';
        if (data === '') return 'assets/img/no-image.svg';

        const picture: string = data;

        return picture.includes('https')
            ? picture
            : environment.URL_FILE + picture;
    }

    private getFullName(data: any): string {
        return (
            this.titlecase.transform(`${data?.firstName} ${data?.lastName}`) ||
            ''
        );
    }

    private getNumberStart(data: any): string {
        return data.info.numberStarts || '';
    }

    // private getNumberStartPain(data: any): string {
    //     // for (const numberStart of numberStarts) {
    //     //     if (numberStart.raiting == data.info.numberStarts)
    //     //         return String(numberStart.number);
    //     // }

    //     return '0';
    // }

    private getStats(data: any, field: string): string {
        return data.stats ? String(data.stats[field]) : '';
    }

    private getDescription(data: any): string {
        return data.info.description || '';
    }

    private getAcknowledgmentKind(data: any): string {
        return data.acknowledgments ? data.acknowledgments.kind || '' : '';
    }

    private getTitle(data: any): string {
        const words: string[] = data.info.title.split(' ');

        if (words.length > 1) {
            words[0] = this.titlecase.transform(words[0]);
            return words.join(' ');
        }

        return this.titlecase.transform(data.info.title) || '';
    }

    private getProfileImage(data: any): string {
        if (!data.picture) return 'assets/img/icons/user.svg';

        if (data.picture.length === 0) return 'assets/img/icons/user.svg';

        const picture: string = data.picture[0];

        return picture.includes('https')
            ? picture
            : environment.URL_FILE + picture;
    }

    private getFollowerImage(data: any): string {
        if (!data.picture) return 'assets/img/icons/user.svg';

        if (data.picture === '') return 'assets/img/icons/user.svg';

        const picture: string = data.picture;

        return picture.includes('https')
            ? picture
            : environment.URL_FILE + data.picture;
    }

    private getUserImage(data: any): string {
        if (!data.info.picture) return 'assets/img/icons/user.svg';

        if (data.info.picture == '') return 'assets/img/icons/user.svg';

        const picture: string = data.info.picture;

        return picture.includes('https')
            ? picture
            : environment.URL_FILE + picture;
    }
}
